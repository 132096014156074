export const PROFILING = {
    //ROLE
    ROLE: {
        AGENT: 'eportal.role.agent',
        AGENCY: 'eportal.role.agency',
        MANAGER: 'eportal.role.areamanager',
        CUSTOMER: 'eportal.role.customer',
        BO: 'eportal.role.bo',
        PARTNER: 'eportal.role.partner'
    },
    ADMIN: 'ROLE_ADMINISTRATOR',
    //FUNCTION
    AGENCY: {
        CONFIGURATION: 'eportal.agency.configuration',
        CREATE: 'eportal.agency.create',
        READ: 'eportal.agency.read',
        UPDATE: 'eportal.agency.update',
        DELETE: 'eportal.agency.delete',
        IMPORT: 'eportal.agency.import',
        EXPORT: 'eportal.agency.export',
        ADMIN: 'eportal.agency.admin'
    },
    BPARTNER: {
        STATUS: 'eportal.bpartner.status',
        CREATE: 'eportal.bpartner.create',
        READ: 'eportal.bpartner.read',
        UPDATE: 'eportal.bpartner.update',
        DELETE: 'eportal.bpartner.delete',
        IMPORT: 'eportal.bpartner.import',
        EXPORT: 'eportal.bpartner.export',
        ADMIN: 'eportal.bpartner.admin'
    },
    BUSINESSLOG: {
        CREATE: 'eportal.businesslog.create',
        READ: 'eportal.businesslog.read',
        UPDATE: 'eportal.businesslog.update',
        DELETE: 'eportal.businesslog.delete',
        IMPORT: 'eportal.businesslog.import',
        EXPORT: 'eportal.businesslog.export',
        ADMIN: 'eportal.businesslog.admin'
    },
    CAMPAIGN: {
        CREATE: 'eportal.campaign.create',
        READ: 'eportal.campaign.read',
        UPDATE: 'eportal.campaign.update',
        DELETE: 'eportal.campaign.delete',
        IMPORT: 'eportal.campaign.import',
        EXPORT: 'eportal.campaign.export',
        ADMIN: 'eportal.campaign.admin'
    },
    CHURNRATE: {
        CREATE: 'eportal.churnrate.create',
        READ: 'eportal.churnrate.read',
        UPDATE: 'eportal.churnrate.update',
        DELETE: 'eportal.churnrate.delete',
        IMPORT: 'eportal.churnrate.import',
        EXPORT: 'eportal.churnrate.export',
        ADMIN: 'eportal.churnrate.admin'
    },
    ANALYSIS_PRICE_VOLUME: {
        EXPORT: 'eportal.analysispricevolume.export'
    },
    CONTACT: {
        CREATE: 'eportal.contact.create',
        READ: 'eportal.contact.read',
        UPDATE: 'eportal.contact.update',
        DELETE: 'eportal.contact.delete',
        IMPORT: 'eportal.contact.import',
        EXPORT: 'eportal.contact.export',
        ADMIN: 'eportal.contact.admin'
    },
    CUSTOMER: {
        CREATE: 'eportal.customer.create',
        READ: 'eportal.customer.read',
        UPDATE: 'eportal.customer.update',
        DELETE: 'eportal.customer.delete',
        IMPORT: 'eportal.customer.import',
        EXPORT: 'eportal.customer.export',
        ADMIN: 'eportal.customer.admin'
    },
    ANAGRAPHIC: {
        UPDATE: 'eportal.anagraphic.update'
    },
    DASHBOARD: {
        CREATE: 'eportal.dashboard.create',
        READ: 'eportal.dashboard.read',
        UPDATE: 'eportal.dashboard.update',
        DELETE: 'eportal.dashboard.delete',
        IMPORT: 'eportal.dashboard.import',
        EXPORT: 'eportal.dashboard.export',
        ADMIN: 'eportal.dashboard.admin'
    },
	CONTRACT: {
        CREATE: 'eportal.contract.create',
        READ: 'eportal.contract.read',
        UPDATE: 'eportal.contract.update',
        DELETE: 'eportal.contract.delete',
        IMPORT: 'eportal.contract.import',
        EXPORT: 'eportal.contract.export',
        ADMIN: 'eportal.contract.admin'
	},
    INSPECTION: {
        CREATE: 'eportal.inspection.create',
        READ: 'eportal.inspection.read',
        UPDATE: 'eportal.inspection.update',
        DELETE: 'eportal.inspection.delete',
        IMPORT: 'eportal.inspection.import',
        EXPORT: 'eportal.inspection.export',
        ADMIN: 'eportal.inspection.admin'
	},
    INVOICE: {
        CREATE: 'eportal.invoice.create',
        READ: 'eportal.invoice.read',
        UPDATE: 'eportal.invoice.update',
        DELETE: 'eportal.invoice.delete',
        IMPORT: 'eportal.invoice.import',
        EXPORT: 'eportal.invoice.export',
        ADMIN: 'eportal.invoice.admin'
	},
    LEAD: {
        CONVERT: 'eportal.lead.convert',
        CREATE: 'eportal.lead.create',
        READ: 'eportal.lead.read',
        UPDATE: 'eportal.lead.update',
        DELETE: 'eportal.lead.delete',
        IMPORT: 'eportal.lead.import',
        EXPORT: 'eportal.lead.export',
        ADMIN: 'eportal.lead.admin'
	},
    OPPORTUNITY: {
        CHANGESTATUS: 'eportal.opportunity.changestatus',
        CREATE: 'eportal.opportunity.create',
        READ: 'eportal.opportunity.read',
        UPDATE: 'eportal.opportunity.update',
        DELETE: 'eportal.opportunity.delete',
        IMPORT: 'eportal.opportunity.import',
        EXPORT: 'eportal.opportunity.export',
        ADMIN: 'eportal.opportunity.admin'
	},
    OTHER: {
        BPQUALIFICATION_DELETE: 'eportal.bpartnerqualification.delete',
        BPRELATION_DELETE: 'eportal.bpartnerRelation.delete',
        USERMANAGEMENT_CREATE: 'commons.usermanagement.create',
        USERMANAGEMENT_VIEW: 'commons.usermanagement.view',
        WORKFLOW_RETRY: 'commons.workflow.retry'
    },
	PRECONTRACT: {
        CREATE: 'eportal.precontract.create',
        READ: 'eportal.precontract.read',
        UPDATE: 'eportal.precontract.update',
        DELETE: 'eportal.precontract.delete',
        IMPORT: 'eportal.precontract.import',
        EXPORT: 'eportal.precontract.export',
        ADMIN: 'eportal.precontract.admin',     
    },
	CTE: {
        PRINT: 'eportal.cte.print', 
        SCHEDEPRINT: 'eportal.cte.schedeprint',   
    },
    ESIGNANY: {
        CREATE: 'eportal.esignany.create',
        DOWNLOAD_CONTRACT: 'eportal.esignany.download.contract',
        DOWNLOAD_AUDITTRAIL: 'eportal.esignany.download.audittrail'
    },
    PRICELIST: {
        CREATE: 'eportal.priceList.create',
        READ: 'eportal.priceList.read',
        UPDATE: 'eportal.priceList.update',
        DELETE: 'eportal.priceList.delete',
        IMPORT: 'eportal.priceList.import',
        EXPORT: 'eportal.priceList.export',
        ADMIN: 'eportal.priceList.admin'        
    },
    TASK: { //Appuntamenti, TODO, Attività, gestione calendari
        CREATE: 'eportal.task.create',
        READ: 'eportal.task.read',
        UPDATE: 'eportal.task.update',
        DELETE: 'eportal.task.delete',
        IMPORT: 'eportal.task.import',
        EXPORT: 'eportal.task.export',
        ADMIN: 'eportal.task.admin'        
    },
    TICKET: {
        ADVANCED: 'eportal.ticket.advanced',
        CREATE: 'eportal.ticket.create',
        READ: 'eportal.ticket.read',
        UPDATE: 'eportal.ticket.update',
        DELETE: 'eportal.ticket.delete',
        IMPORT: 'eportal.ticket.import',
        EXPORT: 'eportal.ticket.export',
        ADMIN: 'eportal.ticket.admin'        
    }
};

import { useSelector } from 'react-redux';
import tenantsService from 'src/services/tenantService';
import { PROFILING } from 'src/utils/profilingKeys';

class UserInfo {
	is = autority => {
		if (autority == undefined) return true;

		const account = useSelector(state => state.account);
		if (account.user.authorities) {
			for (var item of account.user.authorities) {
				if (item.id === autority) return true;
			}
		}
		return false;
	};

	can = func => {
		if (func == undefined) return true;

		const account = useSelector(state => state.account);

		if (account?.user?.allFunctions) {
			for (var f of account.user.allFunctions) {
				if (f.id == func || this.wildcardRegexpMatch(func, f.id) == true)
					return true;
			}
		}

		return false;
	};
	isBo = () => {
		return this.can(PROFILING.ROLE.BO);
	};
	isCustomer = () => {
		return (
			this.can(PROFILING.ROLE.CUSTOMER) &&
			!this.can(PROFILING.ROLE.AGENCY) &&
			!this.can(PROFILING.ROLE.AGENT) &&
			!this.can(PROFILING.ROLE.BO)
		);
	};
	isAgent = () => {
		return this.can(PROFILING.ROLE.AGENT) && !this.can(PROFILING.ROLE.BO);
	};
	isAgency = () => {
		return this.can(PROFILING.ROLE.AGENCY) && !this.can(PROFILING.ROLE.BO);
	};

	agent = () => {
		const account = useSelector(state => state.account);
		const tenantId = tenantsService.getCurrentTenant()?.id;

		if (account.user.allFunctions) {
			let func = account.user.allFunctions.find(
				f => f.id === PROFILING.ROLE.AGENT
			);
			if (func && account.user.extensions?.profiles) {
				let profile = account.user.extensions.profiles.find(
					f => f.tenantId === tenantId
				);
				if (profile) return profile.bpartnerId;
			}
		}

		return undefined;
	};

	getBpartner = () => {
		const account = useSelector(state => state.account);
		const tenantId = tenantsService.getCurrentTenant()?.id;
		let bpartner = null;
			if (account.user.extensions?.profiles) {
				let profile = account.user.extensions.profiles.find(
					f => f.tenantId === tenantId
				);
				if (profile) {
					bpartner = {id:profile?.bpartnerId , name:profile?.bpartnerName}
				}
			}

		return bpartner;
	};

	wildcardRegexpMatch = (func, wildcardFunction) => {
		// Trasforma wildCardFunction in reg exp
		var s = '^';
		for (var i = 0; i < wildcardFunction.length; i++) {
			var funcChar = wildcardFunction.charAt(i);
			if (funcChar == '*') {
				s += '.*';
			} else if (funcChar == '.') {
				s += '\\.';
			} else {
				s += funcChar;
			}
		}
		s += '$';
		// console.log("La funzione utente: " + wildcardFunction + " ==> " + s + " - " + func);

		var re = new RegExp(s);

		// console.log("Result RE: " + (re.test(func)) );
		return re.test(func);
	};
}

const userInfo = new UserInfo();

export default userInfo;

import React from 'react';
import {
	Badge,
	Box,
	Button,
	IconButton,
	Popover,
	SvgIcon,
	TextField,
	Tooltip,
	Typography,
	makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import TimeZoneIcon from '@mui/icons-material/Schedule';
import useSettings from 'src/hooks/useSettings';
import { TRANSLATE,LANGUAGES } from 'src/submodules/commons-misc/constant';
import { useRef } from 'react';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {getTimeZoneList, saveOffset } from 'src/utils/common';

const useStyles = makeStyles((theme) => ({
	badge: {
		height: 10,
		width: 10,
		borderRadius: 5,
		marginTop: 10,
		marginRight: 5
	},
	popover: {
		width: 320,
		padding: theme.spacing(2)
	}
}));

function TimeZone() {
	const classes = useStyles();
	const ref = useRef(null);
	const [isOpen, setOpen] = useState(false);
	const { t,i18n } = useTranslation();
	const { settings, saveSettings } = useSettings();
	const keyFromStorage = localStorage.getItem('i18nextLng');
	const settingsFromCookies = Cookies.get('settings');
	const isMountedRef = useIsMountedRef();

	const [values, setValues] = useState({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		theme: settings.theme,
		language: settings.language,
		timezone : settings.timezone,
		fleet: settings.fleet
	});

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = (field, value) => {
		setValues({
			...values,
			[field]: value
		});
	};

	const changeTimezone = timezone => {
		const timeZoneList = getTimeZoneList();
		var offsetTimezone = timeZoneList.filter(t=>t.id === parseInt(timezone));
		saveOffset(offsetTimezone[0]);
		window.location.reload();
	}

	const handleSave = () => {
		handleClose();
		saveSettings(values)
		changeTimezone(values.timezone);
	};

	useEffect(()=>{
		if(isMountedRef.current){
			if(settingsFromCookies){
				i18n.changeLanguage(JSON.parse(settingsFromCookies)?.language)
			}
		}
	},[isMountedRef]);

  	return (
		<>
		<Tooltip title={"Timezone"}>
			<Badge
				color="secondary"
				classes={{ badge: classes.badge }}
			>
				<IconButton
					color="inherit"
					onClick={handleOpen}
					ref={ref}
				>
					<SvgIcon fontSize="small">
						<TimeZoneIcon />
					</SvgIcon>
				</IconButton>
			</Badge>
		</Tooltip>
		<Popover
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			classes={{ paper: classes.popover }}
			anchorEl={ref.current}
			onClose={handleClose}
			open={isOpen}
		>
			<Typography
				variant="h4"
				color="textPrimary"
			>
				{"Timezone"}
			</Typography>
			<Box mt={2}>
				<TextField
					fullWidth
					label={"Timezone"}
					name="language"
					onChange={(event) => handleChange('timezone', event.target.value)}
					select
					SelectProps={{ native: true }}
					value={values.timezone}
					variant="outlined"
				>
					 {getTimeZoneList().map((timezone) => {
						return (
							<option
								key={timezone?.id}
								value={timezone?.id}
							>
								{timezone?.label}
							</option>
						)
					})} 
				</TextField>
			</Box>

			<Box mt={2}>
				<Button
					variant="contained"
					color="secondary"
					fullWidth
					onClick={handleSave}
				>
					{t(TRANSLATE.SAVE)}
				</Button>
			</Box>
		</Popover>
	</>
  	);
}

export default TimeZone;

import Cookies from 'js-cookie';
import { getDomainName } from './common';

export function restoreSettings() {
  let settings = null;

  try {
    const storedData = localStorage.getItem('settings');
    const storedCookie =  Cookies.get('settings');

    if(storedCookie){
      settings = JSON.parse(storedCookie);
    }else if (storedData) {
      settings = JSON.parse(storedData);
    }
   
  } catch (err) {
    // If stored data is not a strigified JSON this might fail,
    // that's why we catch the error
  }

  return settings;
}

export function storeSettings(settings) {
  localStorage.setItem('settings', JSON.stringify(settings));
  Cookies.set('settings', JSON.stringify(settings),{domain:getDomainName()});

}

export function getStoredSettings() {
  const storedCookie =  Cookies.get('settings');
  if(storedCookie){
    return storedCookie;
  }else{
    return localStorage.getItem('settings');
  }
}

export function clearStoredSettings() {
  Cookies.remove('settings',{domain:getDomainName()});
  return localStorage.removeItem('settings');
}


import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
	CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

function MissingParameter({open, title, message, confirmText, confirmHandle, confirmLoader}) {

	return (
		<Dialog open={open} disableBackdropClick disableEscapeKeyDown>
            {open && (
				<>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{message}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button 
							onClick={() => confirmHandle()} 
							color="primary" 
							disabled={Boolean(confirmLoader)}
							endIcon={confirmLoader && <CircularProgress size={20}/>}
						>
							{confirmText}
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};

MissingParameter.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    confirmText: PropTypes.string.isRequired,
    confirmHandle: PropTypes.func.isRequired
};

export default MissingParameter;
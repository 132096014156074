import React, { useState } from 'react';
import { TRANSLATE } from 'src/submodules/commons-misc/constant';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
	Backdrop,
	Button,
	Grid,
	Link,
	Typography,
	makeStyles
} from '@material-ui/core';
import { getDomainName } from 'src/utils/common';
import { useEffect } from 'react';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Cookies from 'js-cookie';

const useStyles = makeStyles(theme => ({
	root: {
		alignItems: 'baseline',
		backgroundColor: '#ffff',
		color: '#000000',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		position: 'fixed',
		width: '99%',
		zIndex: 999,
		bottom: 0,
		marginLeft: 10,
		marginRight: 10,
		marginBottom: 10,
		height: 150,
		borderRadius: 10
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	noBackdrop: {
		zIndex: theme.zIndex.drawer
	},
	container: {
		height: 140,
		justifyContent: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		position: 'relative',
		top: 10,
		bottom: 10,
		width: '99%'
	},
	txt: {
		height: '100%',
		textAlign: 'center',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
		color: '#000000',
		fontSize: 30,
		position: 'relative'
	},
	button: {
		height: '100%',
		textAlign: 'center',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
		fontSize: 30,
		position: 'relative',
		top: 60
	},
	link: {
		color: theme.overrides.MuiButton.containedSecondary.backgroundColor
	}
}));

function CookieDisclaimer() {
	const { t } = useTranslation();
	const classes = useStyles();
	const [close, setClose] = useState(Cookies.get('CookieConsent'));
	const [show, setShow] = useState(Cookies.get('CookieConsent'));
	const isMountedRef = useIsMountedRef();
	function closeDisclaimer() {
		Cookies.set('CookieConsent', true, { domain: getDomainName() });
		setClose(!close);
	}
	function isInCookiePage() {
		console.log(
			window.location.href,
			window.location.href.indexOf('/cookie') > 1
		);
		if (Boolean(window.location.href.indexOf('/cookie') > 1)) {
			setShow(!show);
		}
	}

	useEffect(() => {
		if (isMountedRef.current) {
			isInCookiePage();
		}
	}, [isMountedRef]);

	return (
		<>
			{!show && (
				<Backdrop className={classes.backdrop} open={!close}>
					<div className={classes.root}>
						<Grid container spacing={1} className={classes.container}>
							<Grid item xs={10} md={10} className={classes.txt}>
								<Grid container>
									<Grid
										item
										xs={12}
										md={12}
										style={{ top: 10, marginLeft: 20, marginBottom: 10 }}
									>
										<Typography align="left" variant="h2" color="textPrimary">
											{`Telemago utilizza i cookies per migliorare l’esperienza di navigazione`}
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										md={12}
										style={{ top: 40, marginLeft: 20 }}
									>
										<Typography variant="h4" color="textPrimary">
											Telemago utilizza cookie tecnici propri per migliorare e
											personalizzare la tua esperienza di navigazione. Per
											maggiori informazioni su utilizzo e gestione dei cookie
											visualizza la nostra{' '}
											<Link
												color="textSecondary"
												component={RouterLink}
												to="/cookie"
												variant="h4"
												className={classes.link}
												target="_blank"
											>
												{`Informativa estesa Cookie`}
											</Link>
											{'. '}
											Chiudendo questa notifica cliccando su "Chiudi" acconsenti
											al nostro utilizzo dei cookie.
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={2} md={2} className={classes.button}>
								<Button
									color="secondary"
									type="button"
									size="large"
									onClick={() => closeDisclaimer()}
									variant="contained"
								>
									{t(TRANSLATE.CLOSE)}
								</Button>
							</Grid>
						</Grid>
					</div>
				</Backdrop>
			)}
		</>
	);
}

export default CookieDisclaimer;

class G7Service {
	isG7() {
		return Boolean(window.location.href.indexOf('/g7/') > 1);
	}

	saveG7Param() {
		localStorage.setItem('G7', true);
	}

	removeG7Param() {
		localStorage.removeItem('G7');
	}
}

const g7Service = new G7Service();

export default g7Service;
import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
	root:{
		backgroundColor:'#ffffff'
	},
	image: {
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
			opacity: 0.3,
			cursor: 'pointer',
		}
	}
}));

function ImageChooser({ itemData,selectedImage,fieldValue,handleClose }) {
	const classes = useStyles();
	function selectImage(selected){
		selectedImage(fieldValue,selected);
		handleClose();
	}
	return (
		<ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={160} className={classes.root}>
		{itemData.map((item) => (
		  <ImageListItem key={item.id} className={classes.image}>
			<img
			  src={`${item.path}${item.value}?w=40&h=40&fit=crop&auto=format`}
			  srcSet={`${item.path}${item.value}?w=40&h=40&fit=crop&auto=format&dpr=2 2x`}
			  alt={item.title}
			  loading="lazy"
			  onClick={()=>selectImage(item.value)}
			/>
		  </ImageListItem>
		))}
	  </ImageList>
	);
}


export default ImageChooser;

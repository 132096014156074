import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
	createStyles,
	jssPreset,
	makeStyles,
	StylesProvider,
	ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/submodules/ui-components/theme';
import Routes from 'src/Routes';
import { appMobileService } from './services';
import configService from 'src/services/configService';
import { CONSTANT_KEYS } from 'src/submodules/commons-misc/constant';
import { AlertComponent } from './components/AlertComponent';
import refreshTokenService from './submodules/commons-auth/Refresh/refreshService';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
	createStyles({
		'@global': {
			'*': {
				boxSizing: 'border-box',
				margin: 0,
				padding: 0
			},
			html: {
				'-webkit-font-smoothing': 'antialiased',
				'-moz-osx-font-smoothing': 'grayscale',
				height: '100%',
				width: '100%'
			},
			body: {
				height: '100%',
				width: '100%'
			},
			'#root': {
				height: '100%',
				width: '100%'
			}
		}
	})
);

function App() {
	useStyles();

	const { settings } = useSettings();
	const privacy_url = configService.getConfigByKey(CONSTANT_KEYS.PRIVACY_URL);
	const isMobileApp = appMobileService.isMobile();

	if (isMobileApp) {
		appMobileService.addMeta();
	}

	const refresh = configService.getConfigByKey(
		CONSTANT_KEYS.TIMER_REFRESH_TOKEN
	);
	const [count, setCount] = React.useState(0);

	useEffect(() => {
		if (refresh) {
			const timer = () => {
				setCount(count + 1);
				refreshTokenService.refreshToken();
			};
			const id = setInterval(timer, refresh * 1000);
			return () => clearInterval(id);
		}
	}, [count, refresh]);

	return (
		<ThemeProvider theme={createTheme(settings)}>
			<StylesProvider jss={jss}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<SnackbarProvider maxSnack={1}>
						<Router history={history}>
							<Auth>
								<ScrollReset />
								<GoogleAnalytics />
								{!isMobileApp && privacy_url && (
									<CookiesNotification privacy_url={privacy_url} />
								)}
								{/* <SettingsNotification /> */}
								<AlertComponent />
								<Routes />
							</Auth>
						</Router>
					</SnackbarProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</ThemeProvider>
	);
}

export default App;

export const PROFILING = {
    //ROLE
    ADMIN: 'ROLE_ADMINISTRATOR',
    //FUNCTION
    LEAD: {
        CREATE: 'eportal.lead.create',
        DELETE: 'eportal.lead.delete'
	},
    OPPORTUNITY: {
        CREATE: 'eportal.opportunity.create',
        DELETE: 'eportal.opportunity.delete'
	},
    OTHER: {
        USERMANAGEMENT_CREATE: 'commons.usermanagement.create',
        USERMANAGEMENT_VIEW: 'commons.usermanagement.view',
        WORKFLOW_RETRY: 'commons.workflow.retry'
    },
    TASK: { //Appuntamenti, TODO, Attività, gestione calendari
        CREATE: 'eportal.task.create',
        UPDATE: 'eportal.task.update',
        DELETE: 'eportal.task.delete',
        EXPORT: 'eportal.task.export'   
    },
    CAMPAIGN: {
        IMPORT: 'eportal.campaign.import'
    },
    TELEMAGO:{
        VIEW:'telemago.portal.view'
    }
};

import axios from 'src/utils/axios';

export const GET_DOWNLOAD = '@download/get-download';

export function getDownload(id) {

    return (dispatch) => {
        axios.get('/api/v1/downloads/' + id).then((response) => dispatch({
            type: GET_DOWNLOAD,
            payload: response.data.results
        }));
    };
}

import axios from 'src/utils/axios';
import { URLS } from 'src/submodules/commons-system/constants/urls';
import authService from 'src/services/authService';
import Cookies from 'js-cookie';
import { getDomainName } from 'src/utils/common';
import g7Service from 'src/submodules/commons-auth/G7/G7Service';
class RefreshTokenService {


	refreshToken = () =>
	new Promise((resolve, reject) => {
		const isG7View = g7Service.isG7();
		let token = sessionStorage.getItem('refreshToken') ? sessionStorage.getItem('refreshToken') : Cookies.get('refreshToken');
		var _params = {
			refresh_token: token
		};
		var _header = {
			Authorization: `Bearer ${authService.getAccessToken()}` 
		}

		if(authService.isAuthenticated && this.refreshTokenIsValid(token) && !isG7View){
			axios
			.get(URLS.PROFILES.REFRESH,{headers:_header,params:_params})
			.then(response => {
				if (response.data) {
						let accessToken = response.data.accessToken;
						let refreshToken = response.data.refreshToken;
						this.saveRefreshToken(refreshToken);
                        authService.setSession(accessToken);
				} else {
					reject(response.data.error);
				}
			})
			.catch(error => {
				reject(error);
			});
		}
	});

	saveRefreshToken = accessToken => {
		sessionStorage.setItem('refreshToken', accessToken);
		Cookies.set('refreshToken', accessToken,{domain:getDomainName()});
	};

	refreshTokenIsValid = refreshToken =>{
		if(refreshToken && refreshToken !== null && refreshToken !== "null"){
			return true;
		}
		return false;
	};

}

const refreshTokenService = new RefreshTokenService();

export default refreshTokenService;

import { TRANSLATE } from 'src/submodules/commons-misc/constant';

export const STORAGE_KEY = 'searchbox';

export const OPERATORS_LIST = [
	{
		label: TRANSLATE.CONTAINS,
		value: TRANSLATE.CONTAINS,
		name: 'like',
		propertyTypes: ['text']
	},
	{
		label: TRANSLATE.GREATER_THAN_OR_EQUAL,
		value: '>=',
		name: '>=',
		propertyTypes: ['date', 'number']
	},
	{
		label: TRANSLATE.LESS_THAN_OR_EQUAL,
		value: '<=',
		name: '<=',
		propertyTypes: ['date', 'number']
	},
	{
		label: TRANSLATE.EQUAL,
		value: '=',
		name: '=',
		propertyTypes: ['date', 'enum', 'pipeline']
	},
	{
		label: TRANSLATE.STARTS_WITH,
		value: TRANSLATE.STARTS_WITH,
		name: 'startWith',
		propertyTypes: []
	}
]
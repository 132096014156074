import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardMedia,
	colors,
	Container,
	Link,
	makeStyles,
	Typography
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import React, { useCallback, useEffect, useState } from 'react';
import Logo from 'src/components/Logo';
import Page from 'src/components/Page';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import configService from 'src/services/configService';
import { CONSTANT_KEYS } from 'src/submodules/commons-misc/constant';

const useStyles = makeStyles(theme => ({
	root: {
		justifyContent: 'center',
		backgroundColor: theme.palette.background.dark,
		display: 'flex',
		minHeight: '100%',
		flexDirection: 'column',
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(1)
	},
	backButton: {
		marginLeft: theme.spacing(2)
	},
	card: {
		overflow: 'visible',
		display: 'flex',
		position: 'relative',
		'& > *': {
			flexGrow: 1,
			flexBasis: '50%',
			width: '50%'
		},
		margin: theme.spacing(2)
	},
	content: {
		padding: theme.spacing(8, 4, 3, 4)
	},
	icon: {
		backgroundColor: colors.green[500],
		color: theme.palette.common.white,
		borderRadius: theme.shape.borderRadius,
		padding: theme.spacing(1),
		position: 'absolute',
		top: -32,
		left: theme.spacing(3),
		height: 64,
		width: 64
	},
	media: {
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
		padding: theme.spacing(3),
		color: theme.palette.common.white,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			display: 'none'
		},
		backgroundSize: 'contain'
	},
	logo: {
		flex: '1 1 auto'
	},
	imageApp: {
		marginRight: theme.spacing(1),
		height: 50,
		width: 150
	}
}));

function AuthLayout({ children }) {
	const classes = useStyles();
	const isMountedRef = useIsMountedRef();
	const [appName, setAppName] = useState(null);
	const [urlGoogle, setUrlGoogle] = useState(null);
	const [urlApple, setUrlApple] = useState(null);
	const [logoLogin, setLogoLogin] = useState(null);

	// const getConfig = useCallback(() => {
	// 	setAppName(configService.getConfigByKey(CONSTANT_KEYS.BE_APP_NAME));
	// 	setUrlGoogle(
	// 		configService.getConfigByKey(CONSTANT_KEYS.URL_GOOGLE_PLAY_APP)
	// 	);
	// 	setUrlApple(configService.getConfigByKey(CONSTANT_KEYS.URL_APPLE_APP));
	// }, [isMountedRef]);

	const getLogo = useCallback(() => {
		configService.getLogoLogin().then(res => {
			setLogoLogin(res);
		});
	}, [isMountedRef]);

	useEffect(() => {
		// getConfig();
		getLogo();
	}, [getLogo]);

	return (
		<Page className={classes.root} title="Login">
			<Container maxWidth="md">
				<Card className={classes.card}>
					<CardContent className={classes.content}>
						<Avatar className={classes.icon}>
							<LockIcon fontSize="large" />
						</Avatar>

						{children}
					</CardContent>
					<CardMedia className={classes.media} title="Telemago Portal">
						<Box
							alignItems="center"
							display="flex"
							mt={3}
							p={3}
							className={classes.logo}
						>
							<Box>
								<Logo size="large" src={logoLogin} />
								<Box
									alignItems="center"
									display="flex"
									pl={3}
									pr={3}
									ml={3}
									mg={3}
								>
									{urlApple && (
										<Link
											component="a"
											color="inherit"
											underline="always"
											href={urlApple}
											target="_blank"
										>
											<img
												alt="AppStore"
												className={classes.imageApp}
												src="/static/images/AppStore.png"
											/>
										</Link>
									)}
									{urlGoogle && (
										<Link
											component="a"
											color="inherit"
											underline="always"
											href={urlGoogle}
											target="_blank"
										>
											<img
												alt="GooglePlay"
												className={classes.imageApp}
												src="/static/images/GooglePlay.png"
											/>
										</Link>
									)}
								</Box>
							</Box>
						</Box>
						<Typography variant="body2" color="textSecondary">
							{appName}
						</Typography>
					</CardMedia>
				</Card>
			</Container>
		</Page>
	);
}

export default AuthLayout;

import { PAGE_SIZE } from '../config';
import { filtersService } from './filters.service';

export class TableService {
	getFilteredRows(rows, pages,pageSize = null) {
		var sizeList = pageSize !== null ? pageSize : PAGE_SIZE;
		let filteredRows = [];
		let start = pages[0] * sizeList;
		let stop = start + pages.length * sizeList;

		for (var i = start; i < stop; i++) {
			filteredRows[i] = rows[i];
		}

		return filteredRows;
	}

	getDataToLoad(startIndex, stopIndex, allocatedData,pageSize = null) {
		const pagesInMemory = this.rowsToPages(allocatedData);
		// definisco le pagine in cui mi trovo
		let startPage = this.indexToPage(startIndex);
		let stopPage = this.indexToPage(stopIndex);
		let pagesNumbers = stopPage - startPage + 1;
		let pageToLoad = [];
		var sizeList = pageSize !== null ? pageSize : PAGE_SIZE;

		for (var i = 0; i < pagesNumbers; i++) {
			let page = startPage + i;
			if (!pagesInMemory.includes(page)) pageToLoad.push(page);
		}

		let count = sizeList * pageToLoad.length;
		let start = pageToLoad[0] * sizeList;

		return {
			start: start,
			count: count
		};
	}

	rowsToPages(rows = []) {
		let pages = [];

		rows.forEach((value, index) => {
			let page = this.indexToPage(index);
			if (!pages.includes(page)) pages.push(page);
		});

		return pages;
	}

	rowsByPages(rows = [], pages = [], pageSize = null) {
		let result = [];
		var sizeList = pageSize !== null ? pageSize : PAGE_SIZE;
		let i = pages[0] * sizeList;
		const max = (pages[pages.length - 1] + 1) * sizeList;

		for (i; i < max; i++) {
			result[i] = rows[i];
		}

		return result;
	}

	indexToPage(index, pageSize = null) {
		var sizeList = pageSize !== null ? pageSize : PAGE_SIZE;
		let value = index / sizeList;

		return Math.trunc(value);
	}

	checkDefaultParams(sort, filters, search) {
		let dSort =
			sort &&
			(sort?.options?.find(x => x?.default)?.value ||
				sort?.find(x => x?.default)?.value ||
				null);
		let dFilters = filtersService.getDefault(filters);

		return {
			sort: dSort,
			filters: dFilters
		};
	}
}

export const tableService = new TableService();

import axios from 'src/utils/axios';
import authService from 'src/services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';

export function login(username, password, mobile = null) {
	return async (dispatch) => {
		try {
			dispatch({ type: LOGIN_REQUEST });

			const user = await authService.loginUsingCredentials(username, password, mobile);

			fixUserFunctionData(user);
			
			dispatch({
				type: LOGIN_SUCCESS,
				payload: {
					user
				}
			});
		} catch (error) {
			dispatch({ type: LOGIN_FAILURE });
			throw error;
		}
	};
}

export function setUserData(user) {
	fixUserFunctionData(user);

	return (dispatch) => dispatch({
		type: SILENT_LOGIN,
		payload: {
			user
		}
	});
}

export function logout() {
	return async (dispatch) => {
		authService.logout();

		dispatch({
			type: LOGOUT
		});
	};
}

export function register() {
	return true;
}

export function updateProfile(update) {
	const request = axios.post('/api/v1/profile/profile', { update });

	return (dispatch) => {
		request.then((response) => dispatch({
			type: UPDATE_PROFILE,
			payload: response.data
		}));
	};
}

function fixUserFunctionData(user) {
	const allFunctions = new Map()

	// Copy user functions
	if (user.functions) {
		for (var func of user.functions) {
			allFunctions.set( func.id , func );
		}
	}

	// Copy user group functions (recursive)
	if (user.groups) {
		for (var group of user.groups) {
			fixUserGroupData( allFunctions, group );
		}
	}

	// Store in user profile
	user.allFunctions = new Array();

	for (var func of allFunctions) {
		user.allFunctions.push( func[1] );
	}

	return user;
}

function fixUserGroupData(allFunctions, group) {
	// Copy user functions
	if(group.functions){
		for (var func of group.functions) {
			allFunctions.set( func.id , func );
		}
	}
	
	// Copy user group functions (recursive)
	if (group.groups) {
		for (var grp of group.groups) {
			fixUserGroupData( allFunctions, grp );
		}
	}
}

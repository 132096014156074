import { MAX_PAGES_MEMORY, PAGE_SIZE } from "../config";
import { tableService } from "./table.service";

class DataService {

	reallocate(data = [], start = 0) {
		let result = [];
		let i = start;

		data.forEach(x => {
			result[i] = x;
			i++;
		});

		return result;
	}

	mergeArray(first = [], second = []) {
		let result = first;

		second.forEach((value, index) => {
			if(value) result[index] = value;
		})

		return result;
	}

	manageMemory(memory = [], start = 0,pageSize=null) {
		let result = memory;
		let memoryPages = tableService.rowsToPages(memory);

		const nextPage = tableService.indexToPage(start,pageSize);
		const firstPage = memoryPages[0];
		const lastPage = memoryPages[memoryPages.length - 1];
		
		let forward = null; // true = avanti - false = indietro

		if(lastPage < nextPage) {
			forward = true;
		} else if (firstPage > nextPage) {
			forward = false;
		}

		if (forward && lastPage !== (nextPage - 1)) {
			result = [];
		} else if (forward === false && firstPage !== (nextPage + 1)) {
			result = [];
		} else {
			if (memoryPages.length >= MAX_PAGES_MEMORY) {
				if (forward) {
					memoryPages.shift();
					result = tableService.rowsByPages(memory, memoryPages,pageSize);
				} else {
					memoryPages.pop();
					result = tableService.rowsByPages(memory, memoryPages,pageSize);
				}
			}
		}

		return result

	}

	getFirstIndex(array) {
		let result;
		let i = 0;

		array.forEach(x => {
			if(x && !result) result = i;

			i++;
		})

		return result;
	}
}

export const dataService = new DataService();
import { BehaviorSubject } from "rxjs";

const defaultProps = {};

class AlertService {
	alert$ = new BehaviorSubject(defaultProps);

	create(title, message, buttonText = null) {
		this.alert$.next({ title, message, buttonText });
	}

	dismiss() {
		this.alert$.next(defaultProps);
	}

} 

export const alertService = new AlertService();
import React, { useState, useCallback, useEffect } from 'react';
import tenantsService from 'src/services/tenantService';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	img: {
		width: '100%'
	}
}))

function Logo({ size, ...props }) {
	const classes = useStyles();
	const isMountedRef = useIsMountedRef();
	const [logo, setLogo] = useState(null);

	const getLogo = useCallback(() => {
		if (!props?.src)
			tenantsService.getLogo(size).then(r => {
				setLogo(r);
			});
	}, [isMountedRef])
	
	useEffect(() => {
		getLogo(size);
	}, [getLogo])  

	if (!logo && !props?.src) return null;
	
	return (
		<img
			alt="Logo"
			src={logo}
			className={classes.img}
			{...props}
		/>
	);
	
}

export default Logo;

import { setBreadcrumbs, setPageTitle } from "src/actions/layoutActions";
import axios from 'src/utils/axios';
import { useDispatch } from 'react-redux';
import * as DeviceProps from 'react-device-detect';
import appMobileService from "./appMobile.service";

class LayoutService{
	
	setBreadcrumbs(pages){
		const dispatch = useDispatch();
		dispatch(setBreadcrumbs({ breadcrumbs: pages }));
	}

	setPageTitle(title){
		const dispatch = useDispatch();
		dispatch(setPageTitle({ title: title }));
	}

	isMobile() {
		const isMobileApp = appMobileService.isMobile()
		
		return DeviceProps.isMobileOnly || isMobileApp;
	}

	isTablet() {
		return DeviceProps.isTablet;
	}

	isDesktop() {
		return !DeviceProps.isMobileOnly && !DeviceProps.isTablet;
	}

}

const layoutService = new LayoutService();

export default layoutService;
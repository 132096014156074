import axios from 'src/utils/axios';
import { URLS } from 'src/submodules/commons-system/constants/urls';
import authService from './authService';
import configService from './configService';

const STORAGE_KEYS = {
	TENANTS: 'tenants',
	CURRENT_TENANT: 'currentTenant'
}

class TenatService {

	setTenants(t){
		localStorage.setItem(STORAGE_KEYS.TENANTS, JSON.stringify(t || null));
				
		// AGGIUNGI CURRENT TENANT AD HEADER "X-SCF-Tenant"
		let currentTenant = tenantsService.getCurrentTenant();

		if(currentTenant) {
			axios.defaults.headers.common['X-SCF-Tenant'] = currentTenant?.id || null;
		}
	}

	getTenants(){
		return JSON.parse(localStorage.getItem(STORAGE_KEYS.TENANTS));
	}

	setCurrentTenant(t){
		localStorage.setItem(STORAGE_KEYS.CURRENT_TENANT, JSON.stringify(t));
		window.location.reload();
	}

	getCurrentTenant(){
		let tenants = this.getTenants();
		let current = JSON.parse(localStorage.getItem(STORAGE_KEYS.CURRENT_TENANT));
	
		if (current) return current;
		
		if (tenants && tenants?.length > 0) {
			localStorage.setItem(STORAGE_KEYS.CURRENT_TENANT, JSON.stringify(tenants[0]));
			
			return JSON.parse(localStorage.getItem(STORAGE_KEYS.CURRENT_TENANT));
		}
	}

	clearStorage(){
		localStorage.removeItem(STORAGE_KEYS.TENANTS);
		localStorage.removeItem(STORAGE_KEYS.CURRENT_TENANT);
		delete axios.defaults.headers.common['X-SCF-Tenant'];
	}

	getLogo(size){
		let defaultLogo = '/static/images/logo-ohm.png';
		let tenant = this.getCurrentTenant();
		let token = authService.getAccessToken();

		if (tenant && token) {

			// if(size === 'large') {
			// 	return axios({
			// 		method: 'GET',
			// 		url: `${URLS.TENANTS}/${tenant.id}/logoLarge`,
			// 		responseType: 'blob',
			// 		headers: {
			// 			Authorization: `Bearer ${token}`
			// 		}
			// 	})
			// 	.then(res => window.URL.createObjectURL(new Blob([res.data])))
			// 	.catch(() => defaultLogo);
	
			// } else if (size === 'small') {
			// 	return axios({
			// 		method: 'GET',
			// 		url: `${URLS.TENANTS}/${tenant.id}/logoSmall`,
			// 		responseType: 'blob',
			// 		headers: {
			// 			Authorization: `Bearer ${token}`
			// 		}
			// 	})
			// 	.then(res => window.URL.createObjectURL(new Blob([res.data])))
			// 	.catch(() => defaultLogo);
			// }	
			
			return new Promise((resolve) => resolve(defaultLogo));
		} else {
			let conf = configService.getConfigByKey();

			return new Promise((resolve) => resolve(conf?.appIcon || defaultLogo));
		}
	}

}

const tenantsService = new TenatService();

export default tenantsService;
class FiltersService {

	add(filter, filters) {
		filters = {
			...filters,
			...filter
		}

		return filters;
	}

	remove(filter, filters) {
		let filterObj = Object.keys(filter);

		filterObj.forEach(x => {
			delete filters[x];
		})

		return filters;
	}


	getActiveParams(params) {
		let _params = { ...params };
		
		delete _params["start"];
		delete _params["count"];

		return _params;
	}

	isActive(filter, filters) {
		let result = null;

		if(filter) {
			let filterObj = Object.keys(filter);

			filterObj.forEach(x => {
				if(result !== false) {
					if(filters?.[x] === filter[x]) {
						result = true;
					}
				}
			})
		} else result = false;

		return result;
	}

	getDefault(filters) {
		let results = {};

		if(filters) {
			let _filters = filters
				.filter( x => x.default)
				.map(y => y.value);

			_filters.forEach(z => {
				results = {
					...results,
					...z
				}
			})
		}

		return results;
	}
}

export const filtersService = new FiltersService();
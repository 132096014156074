import { OPERATORS_LIST } from '../config';
import storageServices from './storage.service';

class LogicService {

	getDefaultValue(searchBoxName, searchParams, searchArray, type) {
		const value = searchParams.find(x => x?.default === true);
		const storeData = searchBoxName && storageServices.get(searchBoxName);

		if (type === 'property') {
			let store = storeData?.[searchBoxName]?.property;
			let findS = searchArray.find(x => x?.property?.name === store?.name);
			let findD = searchArray.find(x => x?.property?.name === value?.name);

			return (!findS && store) || (!findD && value) || null;
		}

		if (type === 'operator') {
			let store = storeData?.[searchBoxName]?.property;
			let findS = searchArray.find(x => x?.property?.name === store?.name);
			let findD = searchArray.find(x => x?.property?.name === value?.name);

			if((store && !findS) || findD)
				return (!findS && storeData?.[searchBoxName]?.operator) || null;

			if (value?.defaultOperator)
				return store || OPERATORS_LIST.find(x => x?.name === value.defaultOperator);
			else
				switch (value) {
					case 'text':
						return store || OPERATORS_LIST.find(x => x?.name === 'contiene');
					case 'date':
					case 'enum':
					case 'pipeline':
					case 'number':
						return store || OPERATORS_LIST.find(x => x?.name === '=');
				}
		}

		if (type === 'value') {
			return storeData?.[searchBoxName] ? null : value?.values?.find(x => x.default === true);
		}

		if (type === 'searchArray') {
			let store = storeData?.[searchBoxName]?.searchArray;
			if (store) {
				let array = JSON.parse(store);
				// setInit(false);
				

				return array;
			}

			return null;
		}

		if (type === 'type') {
			let store = storeData?.[searchBoxName]?.property;
			return store?.type || value?.type;
		}
	}

}

const logicService = new LogicService();

export default logicService;
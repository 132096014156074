import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getStoredSettings } from 'src/utils/settings';
import tenantsService from 'src/services/tenantService';
import useSettings from 'src/hooks/useSettings';

function AuthGuard({ children }) {
  const account = useSelector((state) => state.account);
  const { settings, saveSettings } = useSettings();
  const storedSettings = getStoredSettings();
  const currentTenant = tenantsService.getCurrentTenant();
  
  if (!account.user) {
    return <Redirect to="/auth" />;
  }

  if(currentTenant && !storedSettings) {
    let p = settings;

    if(currentTenant?.properties?.props?.thema)
      p.theme = currentTenant?.properties?.props?.thema;
    
    saveSettings(p);
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;

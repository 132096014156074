import { calculateOffset, saveOffset, saveTimeZoneList } from "src/utils/common";

export function createTimeZoneList(){
    let index = 0;
    let list  = [];
    Intl.supportedValuesOf('timeZone').map(t=> {
        list.push({label:t,id:index,value:calculateOffset(t)});
        index++;
    });

    saveTimeZoneList(list);
    return list;
}
import React from 'react';
import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux'; 
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next";

export default function CustomBreadcrumbs() {
	const history = useHistory();
	const pages = useSelector((state) => state.layout.breadcrumbs);
	const { t } = useTranslation();

	function changeRoute(route, state) {
		history.push(route, state)
	}

	return (
		<Box mx={2}>
			<Breadcrumbs
				separator={<NavigateNextIcon fontSize="small" />}
				aria-label="breadcrumb"
				color="inherit"
			>
				{pages?.map(x => {
					if(x.link) 
						return (
							<Link
								key={x.title}
								variant="body1"
								color="inherit"
								onClick={() => changeRoute(x?.link, x?.state)}
							>
								{t(x.title)}
							</Link>
						)
						else 
							return (
								<Typography
									key={x.title}
									variant="body1"
									color="inherit"
								>
									{t(x.title)}
								</Typography>
							)
				})}
			</Breadcrumbs>
		</Box>
	)
}

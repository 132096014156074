import { STORAGE_KEY } from "../config";

class StorageService {

	clear(searchBoxName) {
		if (searchBoxName) {
			let store = this.get() || {};

			delete store[searchBoxName];

			localStorage.setItem(STORAGE_KEY, JSON.stringify(store));
		}
	}

 	set(searchBoxName, key, value) {
		if (searchBoxName) {
			let store = this.get(searchBoxName) || {};
			let storeName = store[searchBoxName] || {};
			
			if (key === 'searchArray') storeName[key] = JSON.stringify(value);
			else storeName[key] = value;

			store[searchBoxName] = storeName;
			
			localStorage.setItem(STORAGE_KEY, JSON.stringify(store));
		}
	}

	get(searchBoxName) {
		let store = searchBoxName && JSON.parse(localStorage.getItem(STORAGE_KEY));
		return store;
	}

}

const storageService = new StorageService();

export default storageService;
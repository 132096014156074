import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import userInfo from 'src/services/userInfo';
import layoutService from 'src/services/layoutService';
import { PROFILING } from 'src/utils/profilingKeys';
function DesktopGuard({ children }) {
  
  if (layoutService.isMobile()) {
    const isAdmin = userInfo.is("ROLE_ADMINISTRATOR");
    const isAgent = userInfo.can(PROFILING.ROLE.AGENT) || userInfo.can(PROFILING.ROLE.AGENCY);
    const isBo = userInfo.can(PROFILING.ROLE.BO);

    if(!isAdmin && !isAgent && !isBo)
      return <Redirect to='/mobile' />;
  }

  return children;
}

DesktopGuard.propTypes = {
  children: PropTypes.any
};

export default DesktopGuard;

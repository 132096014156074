import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Box,
  IconButton,
  Popover,
  SvgIcon,
  Tooltip,
  makeStyles,
  Card,
  CardHeader,
  colors,
  CircularProgress
} from '@material-ui/core';
import AppsIcon from '@mui/icons-material/Apps';
import {
	Home as HomeIcon
} from '@mui/icons-material';
import { TRANSLATE } from 'src/submodules/commons-misc/constant';
import { useTranslation } from 'react-i18next';
import userInfo from 'src/services/userInfo';
import { PROFILING } from 'src/submodules/commons-misc/profilingKeysCommons';
import configService from 'src/services/configService';
import useIsMountedRef from 'src/hooks/useIsMountedRef';


const useStyles = makeStyles((theme) => ({
	popover: {
		width: 'auto',
    height: 'auto',
		padding: theme.spacing(2)
	},
	item: {
		width: 90,
    textAlign: 'center',
	},
  containter:{
    minWidth:'150px'
  },
  itemContainer: {
		marginTop: 20,
    '&:hover': {
      backgroundColor: theme.palette.appBar.hover,
      opacity: 0.5,
      cursor: 'pointer'
    },
    whiteSpace:'nowrap'
	},
  itemContainerDisabled:{
      marginTop: 20,
      backgroundColor: theme.palette.appBar.hover,
      opacity: 0.5,
      whiteSpace:'nowrap'
  },
  header: {
		background: theme.overrides.MuiButton.containedSecondary.backgroundColor,
    color:'#ffffff'
    },
  icon: {
    padding:3,
    width:'30px',
    height:'30px'
	},
  dot:{
    backgroundColor:colors.green[600],
    border:'1px solid '+colors.green[600],
    borderRadius:'100%',
    width:'10px',
    height:'10px'
  }
}));

function Apps({webApps}) {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpenPopOver, setOpenPopOver] = useState(false);
  const wa = webApps || configService.getWebApps();
  const [isLoading,setIsLoading] = useState(!Boolean(wa));
  const { t } = useTranslation();

	const handleOpenApps = () => {
		setOpenPopOver(true);
	};
	
	const handleCloseNotifier = () => {
		setOpenPopOver(false);
	};

	const goTo = (url) => {
    if(window.location.href.indexOf(url) !== 0){
      localStorage.removeItem("G7");
      window.location.href = url;
    }
	};

  return (
    <>
			<Tooltip title={t(TRANSLATE.APPLICATIONS)}>
        {isLoading ? (
          <React.Fragment>
            <CircularProgress color="inherit" size={20} />
          </React.Fragment>
        ) : (
      <IconButton
        color="inherit"
        onClick={handleOpenApps}
        ref={ref}
      >
        <SvgIcon fontSize="small">
          <AppsIcon />
        </SvgIcon>
      </IconButton>
      )}
						
			</Tooltip>
      <>
      {!isLoading && (
        <Popover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				classes={{ paper: classes.popover }}
				anchorEl={ref.current}
				onClose={handleCloseNotifier}
				open={isOpenPopOver}
			>
        <Box display="flex">
        {wa && wa?.map(w=>{
            if(userInfo.can(w?.auth)){
              return (
                <Box m={2} className={classes.containter}>
                <Card className={window.location.href.indexOf(w?.url) !== 0 ? classes.itemContainer : classes.itemContainerDisabled} 
                  onClick={()=> goTo(w?.url)}>
                  <CardHeader className={classes.header}
                    title={
                      <>
                        <Box display="flex" justifyContent="center">
                            {w?.label}
                        </Box>
                        </>
                        }
                      />
                      <Box display="flex" justifyContent="center" mt={1} mb={1}>
                        <SvgIcon className={classes.icon} >
                            <HomeIcon />
                          </SvgIcon>
                      </Box>
                </Card>
              </Box>
              )
            }
            })}
        </Box>
      </Popover>
      )}
      </>
    </>
  );
}

export default Apps;

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router';
import { appMobileService } from 'src/services';
import qs from 'query-string';

function GuestGuard({ children }) {
  const account = useSelector((state) => state.account);
  const location = useLocation();
  const path = location.pathname;
  const { mobileApp } = qs.parse(location.search, { ignoreQueryPrefix: true });
  
  const isMobileApp = mobileApp || appMobileService.isMobile();
  const devType = appMobileService.getDeviceType();

  if (account.user) {
    return <Redirect to="/app" />;
  }

  if (isMobileApp && devType !== 'android') {
    if (path === '/auth/mobile') return children;
    else return <Redirect to="/auth/mobile" />
  } else {
    if (path === '/auth/mobile') return <Redirect to="/auth" />
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;

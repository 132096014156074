import React, { useState, useRef, useEffect } from 'react';
import {
	Badge,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	Radio,
	RadioGroup,
	SvgIcon,
	TextField,
	Tooltip,
	Typography,
	colors,
	makeStyles
} from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';
import tenantsService from 'src/services/tenantService';
import { useTranslation } from 'react-i18next';
import { TRANSLATE } from 'src/submodules/commons-misc/constant';
import configService from 'src/services/configService';
import { Search as SearchIcon, X as XIcon } from 'react-feather';
import { ArrowRight as ArrowRightIcon } from 'react-feather';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MissingParameter from './MissingParameter';
import { useHistory } from 'react-router';
import { logout } from 'src/actions/accountActions';
import { authService } from 'src/services';
import fleetService from 'src/submodules/ui-components/TopBar/Fleet/fleetService';
const useStyles = makeStyles(theme => ({
	badge: {
		height: 10,
		width: 10,
		borderRadius: 5,
		marginTop: 10,
		marginRight: 5
	},
	popover: {
		width: 320,
		padding: theme.spacing(2)
	},
	action: {
		'& + &': {
			marginLeft: theme.spacing(1)
		}
	},
	startAdornment: {
		display: 'contents',
		alignItems: 'center',
		marginRight: theme.spacing(1)
	},
	row: {
		display: 'flex',
		alignItems: 'center'
	},
	dialog: {
		minHeight: 350
	}
}));

function Fleets() {
	const classes = useStyles();
	const ref = useRef(null);
	const { settings, saveSettings } = useSettings();
	const [isOpen, setOpen] = useState(false);
	const [values, setValues] = useState({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		theme: settings.theme,
		language: settings.language,
		timezone: settings.timezone,
		fleet: settings.fleet
	});
	const tenants = tenantsService.getTenants();
	const [tenant, setTenant] = useState(
		tenantsService.getCurrentTenant()?.id || tenants?.[0]?.id
	);
	const { t, i18n } = useTranslation();
	const fleets = fleetService.getFleets();

	const currentFleet = fleetService.getCurrentfleet();
	const startAdornmentRef = useRef(null);
	const unauthorized = currentFleet == 'UNDEFINED';
	const history = useHistory();
	const handleOpen = () => {
			setOpen(true);
	};

	const handleClose = () => {
		setFilteredResults(fleets);
		setOpen(false);
	};

	const [filteredResults, setFilteredResults] = useState(
		fleetService.getFleets()
	);
	const searchItems = searchValue => {
		if (searchValue !== '') {
			const filteredData = fleets.filter(item => {
				return item?.name
					? (item?.name).toUpperCase().includes(searchValue.toUpperCase())
					: null;
			});
			setFilteredResults(filteredData);
		} else {
			setFilteredResults(fleets);
		}
	};

	const handleSave = fleet => {
		let _values = {
			...values,
			['fleet']: fleet?.path
		};
		var currentF = fleets.filter(f => f.path == fleet?.path);
		localStorage.setItem(
			'currentFleet',
			currentF ? JSON.stringify(currentF[0]) : fleets[0]
		);
		if (isInDetail) {
			history.push('/');
		}
		saveSettings(_values);
		fleetService.addCurrentfleet(currentF ? currentF[0] : fleets[0]);
		setOpen(false);
		let t = tenants.find(x => x.id == tenant);
		tenantsService.setCurrentTenant(t);
	};

	const dismiss = () => {
		authService.logout();
		window.location.reload();
		localStorage.removeItem('currentFleet');
		history.push('/');
	};
	function isInDetail() {
		return Boolean(window.location.href.indexOf('/overview/electric/') > 1);
	}

	return (
		<>
			<Tooltip title={t(TRANSLATE.FLEET)}>
				<Badge color="secondary" classes={{ badge: classes.badge }}>
					<IconButton color="inherit" onClick={handleOpen} ref={ref}>
						<Typography variant="h5" color="textPrimary">
							{currentFleet?.name?.toUpperCase()}
						</Typography>
					</IconButton>
				</Badge>
			</Tooltip>
			<Dialog open={isOpen} maxWidth="md" fullWidth>
				{isOpen && (
					<>
						<DialogContent className={classes.dialog}>
							<TextField
								id="searchBox"
								fullWidth
								autoComplete="off"
								label={t(TRANSLATE.SEARCH)}
								variant="outlined"
								onChange={e => searchItems(e.target.value)}
								InputProps={{
									startAdornment: (
										<Box
											ref={startAdornmentRef}
											className={classes.startAdornment}
										>
											<InputAdornment position="start">
												<SvgIcon
													fontSize="small"
													color="action"
													style={{ marginRight: '8px' }}
												>
													<SearchIcon />
												</SvgIcon>
											</InputAdornment>
										</Box>
									)
								}}
							/>
							<Grid container spacing={1} style={{ padding: 10 }}>
								<Box minWidth={900}>
									<Grid item xs={12} md={12} style={{ marginBottom: 20 }}>
										<Grid container spacing={1}>
											<Grid item xs={4} md={4} style={{width:300}}>
												<Typography variant="body1" color="textPrimary">
													{t(TRANSLATE.NAME)}
												</Typography>
											</Grid>
											<Grid item xs={2} md={2} style={{width:50}}>
												<Typography variant="body1" color="textPrimary">
													{t(TRANSLATE.CODE)}
												</Typography>
											</Grid>
											<Grid item xs={4} md={4} style={{width:750}}>
												<Typography variant="body1" color="textPrimary">
													{t(TRANSLATE.TREE)}
												</Typography>
											</Grid>
											<Grid item xs={2} md={2} style={{width:50 ,alignItems:'right'}}>
												<Typography variant="body1" color="textPrimary">
													{t(TRANSLATE.ACTIONS)}
												</Typography>
											</Grid>
										</Grid>
										<Divider />
									</Grid>
								</Box>
								<PerfectScrollbar options={{ suppressScrollX: true }}>
									<Box maxHeight={350} minWidth={900}>
										{filteredResults.length > 0 &&
											filteredResults.map(f => (
												<Grid item xs={12} md={12}>
													<Grid container spacing={1}>
														<Grid item xs={4} md={4} className={classes.row} style={{width:300}}>
															<Box>
																<Typography
																	variant="subtitle2"
																	color="textPrimary"
																>
																	{f?.name}
																</Typography>
															</Box>
														</Grid>
														<Grid item xs={2} md={2} className={classes.row} style={{width:50}}>
															<Box>
																<Typography
																	variant="subtitle2"
																	color="textPrimary"
																>
																	{f?.code}
																</Typography>
															</Box>
														</Grid>
														<Grid item xs={4} md={4} className={classes.row} style={{width:750}}>
															<Box>
																<Typography
																	variant="subtitle2"
																	color="textPrimary"
																>
																	{f?.pathDescription}
																</Typography>
															</Box>
														</Grid>
														<Grid item xs={2} md={2} style={{width:50,alignItems:'right'}}>
															<Box>
																{Boolean(f?.code === currentFleet?.code) ? (
																	<Tooltip title={t(TRANSLATE.ACTIVE)}>
																		<IconButton>
																			<SvgIcon fontSize="small">
																				<CheckCircleOutlineIcon
																					style={{
																						color: colors.green[600]
																					}}
																				/>
																			</SvgIcon>
																		</IconButton>
																	</Tooltip>
																) : (
																	<Tooltip title={t(TRANSLATE.SAVE)}>
																		<IconButton onClick={() => handleSave(f)}>
																			<SvgIcon fontSize="small">
																				<ArrowRightIcon />
																			</SvgIcon>
																		</IconButton>
																	</Tooltip>
																)}
															</Box>
														</Grid>
													</Grid>
												</Grid>
											))}
									</Box>
								</PerfectScrollbar>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								className={classes.action}
								color="primary"
							>
								{t(TRANSLATE.CLOSE)}
							</Button>
						</DialogActions>
					</>
				)}
			</Dialog>
			<MissingParameter
				open={unauthorized}
				title={t(TRANSLATE.WARNING)}
				message={
					<Box alignItems="center">
						<Typography variant="body2" color="textSecondary">
							{t(TRANSLATE.UNAUTHORIZED)}
						</Typography>
					</Box>
				}
				confirmText={t(TRANSLATE.CLOSE)}
				confirmHandle={() => dismiss()}
			/>
		</>
	);
}

export default Fleets;

export const SET_BREADCRUMBS = '@layout/setBreadcrumbs';
export const SET_PAGE_TITLE = '@layout/setPageTitle';

export function setBreadcrumbs(breadcrumbs) {

  return (dispatch) => {
    dispatch({
      type: SET_BREADCRUMBS,
      payload: breadcrumbs
    });
  };
}

export function setPageTitle(title) {

  return (dispatch) => {
    dispatch({
      type: SET_PAGE_TITLE,
      payload: title
    });
  };
}

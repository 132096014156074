import i18n from 'i18next';
import axios from "src/utils/axios";
import { CONSTANT } from 'src/submodules/commons-misc/constant';
import { URLS } from "src/submodules/commons-system/constants/urls";
import authService from "./authService";
import { createTimeZoneList } from 'src/submodules/ui-components/tz';

const STORAGE_KEY = "beConfig";

class ConfigService {

	setConfig(c){
		localStorage.setItem(STORAGE_KEY, JSON.stringify(c));
	}

	getConfigByKey(key){
		let config = JSON.parse(localStorage.getItem(STORAGE_KEY));

		if(key) return config?.[key];
		else return config;
	}

	getConfig = () =>
	new Promise((resolve, reject) => {
		let isAuth = authService.isAuthenticated();
		let config;
		if (isAuth) {
			config = {
				url: URLS.CONFIG.PRIVATE,
				headers: {
					Authorization: `Bearer ${authService.getAccessToken()}`
				}
			};
		} else {
			config = {
				url: URLS.CONFIG.PUBLIC
			};
		}
		axios({
			type: 'GET',
			...config
		})
			.then(res => {
				this.setConfig(res.data);
				createTimeZoneList();
				if (res.data?.customerTranslation) {
					i18n.changeLanguage(res.data?.customerTranslation);
				}
				resolve(res);
			})
			.catch(error => {
				reject(error);
			});
	});

	getLogoLogin() {
		return axios
			.get(URLS.LOGO_LOGIN, {	responseType: 'blob' })
			.then(res => {
				const file = new Blob([res.data], { type: res.headers['content-type'] });
				return URL.createObjectURL(file);
			})
			.catch(err => console.error("getLogoLogin err", err))
	}


	getWebApps(){
		return this.getConfigByKey(CONSTANT.WEB_APPS);
	}
}

const configService = new ConfigService();

export default configService;

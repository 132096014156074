import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import kanbanReducer from './kanbanReducer';
import layoutReducer from './layoutReducer';
import downloadReducer from './downloadReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  mail: mailReducer,
  kanban: kanbanReducer,
  layout: layoutReducer,
  download: downloadReducer
});

export default rootReducer;

import configService from 'src/services/configService';
import axios from 'src/utils/axios';

const CURRENT_FLEET = 'currentFleet';
const STORAGE_KEY = 'beConfig';

class FleetService {
	getFleets() {
		return configService.getConfigByKey('fleets');
	}

	addCurrentfleet(fleet) {
		localStorage.setItem(CURRENT_FLEET, JSON.stringify(fleet));
	}

	getCurrentfleet() {
		let current = 'UNDEFINED';
		let defaultFleet = null;
		let json = JSON.parse(localStorage.getItem(STORAGE_KEY));
		if (json.fleets && json.fleets?.length > 0) {
			defaultFleet = json.fleets.filter(f => f?.isDefault == true)[0];
			try {
				current = JSON.parse(localStorage.getItem(CURRENT_FLEET));
				if (!current) {
					current = defaultFleet;
					localStorage.setItem(CURRENT_FLEET, JSON.stringify(current));
				}
				axios.defaults.headers.common['Default-Fleet'] = defaultFleet?.code;
			} catch (error) {
				current = defaultFleet;
				localStorage.setItem(CURRENT_FLEET, JSON.stringify(current));
				axios.defaults.headers.common['Default-Fleet'] = defaultFleet?.code;
			}
		}
		return current;
	}

	getPathCurrentFleet() {
		let json = JSON.parse(localStorage.getItem(STORAGE_KEY));
		if (json && json?.fleets && json?.fleets?.length !== 0) {
			let current = JSON.parse(localStorage.getItem(CURRENT_FLEET));
			if (!current) {
				current = json?.fleets.filter(f => f?.isDefault == true)[0];
				localStorage.setItem(CURRENT_FLEET, JSON.stringify(current));
			}
			return current?.path;
		}
		return 'UNDEFINED';
	}

	clearStorage() {
		localStorage.removeItem(CURRENT_FLEET);
		delete axios.defaults.headers.common['Default-Fleet'];
	}

	checkAndSaveCurrentFleet(data){
		if(data?.fleets){
			var saved = localStorage.getItem(CURRENT_FLEET); 
			if(!saved){
				var current = data?.fleets.filter(f => f?.isDefault == true)[0];
				localStorage.setItem(CURRENT_FLEET, JSON.stringify(current));
			}
		}
	}
}

const fleetService = new FleetService();
export default fleetService;

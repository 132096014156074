import axios from "src/utils/axios";
import { URLS } from "src/submodules/commons-system/constants/urls";

class MapService {
	constructor() {
	}

	getMarker(){
		let url = URLS.MARKERS;
		return axios.get(url);
	}

}

export const mapService = new MapService();;

/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_DOWNLOAD } from 'src/actions/downloadActions';

const initialState = {
  download: []
};

const downloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOWNLOAD: {
      const download = action.payload;

      return produce(state, (draft) => {
        draft.download = download;
      });
    }

    default: {
      return state;
    }
  }
};

export default downloadReducer;